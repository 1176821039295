.dropdown {
    /* padding: 0.5vw 0.5vw 0 0.5vw; */
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: 0px; */
    flex: 10vw 0 !important; 
}

.dropdown:hover {
    /* background-color: #fff;
    color: #2f02bc; */
    /* padding: 0.5vw 0.5vw 0 0.5vw; */
}

.dropdown-title {
    margin: 0 2vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border: 0px;
}

.dropdown-title:hover {
    /* background-color: #fff;
    color: #2f02bc; */
}


.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    /* max-width: 15vw; */
    width: 10vw;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9999;
    border: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .dropdown-content div {
    float: none;
    color: #009ee0;
    margin: 16px 0;
    padding: 6px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    border-radius: 5px;
    border: 0px;
  }
  
  .dropdown-content div:hover {
    background-color: #fff;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .dropdown:hover .dropdown-title {
    background-color: #fff;
    color: #2f02bc;      
    display: block;
  }

  /* .dropdown:hover .dropdown-content.orders {
    left: calc(100vw - 63vw);
  } */

  /* .dropdown:hover .dropdown-content.solutions {
    left: calc(100vw - 53.2vw);
  } */