.sng-sign-in {
	width: 350px;
}

.sng-sign-in .header {
	margin: 0px 17px 8px 18px;
}

.sng-sign-in .header .label {
    font-size: 16px;
}


.sng-sign-in .header .title {
    font-size: 16px;
}

.sng-sign-in .linkedin {
    padding-left: 30px;
    padding-right: 30px;
}

.sng-sign-in .linkedin .text {
    margin-top: 5px;
	font-size: 12px;
}

.sng-sign-in .linkedin .button .label-button {
	font-size: 12px;
}

.sng-sign-in .linkedin .button .image {
    height: 24px;
    width: 24px;
    padding: 4px;
}

.sng-sign-in .body {
    padding-left: 30px;
    padding-right: 30px;
}

.sng-sign-in .body .instructions {
    font-size: 14px;
}

.sng-sign-in .body .fields {
    margin-top: 15px;
}

.sng-sign-in .body .fields .email {
    margin-bottom: 15px;
}

.sng-sign-in .body .options {
	margin-top: 14px;
}

.sng-sign-in .body .fields input {
	font-size: 12px;
    height: 33px;
    padding-left: 10px;
    width: calc(100% - 10px)
}

.sng-sign-in .body .options .text {
    font-size: 12px;
}

.sng-sign-in .body .button .label-button {
    font-size: 12px;
}

.sng-sign-in .footer {    
    padding-left: 40px;
    padding-right: 40px;
}

.sng-sign-in .footer .account {
	font-size: 14px;
}